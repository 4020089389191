import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import ProjectBody from '../ProjectBody';

const Team = (): JSX.Element => {

  const data = useStaticQuery(graphql`
    query {
      scLogo: contentfulAsset(title: {eq: "SC-logo"}) {
        fixed(width: 180) {
          ...GatsbyContentfulFixed_noBase64
        }
      }
      fopLogo: contentfulAsset(title: {eq: "FOP-logo"}) {
        fixed(width: 180) {
          ...GatsbyContentfulFixed_noBase64
        }
      }
      wbpLogo: contentfulAsset(title: {eq: "WBP-logo"}) {
        fixed(width: 180) {
          ...GatsbyContentfulFixed_noBase64
        }
      }
    }
  `);

  return (
    <ProjectBody>
      <div className="project-content">
        <h1>KIM JESTEŚMY?</h1>
        <p>
          Po pierwsze, zgranym zespołem. Są wśród nas filozofowie, humaniści i prawnicy, specjaliści z zakresu 
          metodologii, socjologii i filozofii nauki, kolektywnej inteligencji, logiki racjonalnej argumentacji
          i formułowania tez, komputerowego wspomagania argumentacji i informatyki. Nasza organizacja zrzesza
          ekspertów, których kompetencje się przeplatają i wzajemnie się uzupełniają. Taka interdyscyplinarność
          pozwala nam pracować kreatywnie i działać poza utartymi schematami.
        </p>
        <h1>DZIAŁAMY RAZEM</h1>
        <div className="project-content__logos">
          <a 
            className="project-content__logo"
            href="http://optimumpareto.pl/" 
            rel="noopener noreferrer" 
            target="_blank"
          >
            <Img fixed={data.fopLogo.fixed} />
          </a>
          <a 
            className="project-content__logo"
            href="http://swarmcheck.pl/" 
            rel="noopener noreferrer" 
            target="_blank"
          >
            <Img fixed={data.scLogo.fixed} />
          </a>
          <a 
            className="project-content__logo"
            href="https://www.rajska.info/" 
            rel="noopener noreferrer" 
            target="_blank"
          >
            <Img fixed={data.wbpLogo.fixed} />
          </a>
        </div>
      </div>
    </ProjectBody>
  );
};

export default Team;